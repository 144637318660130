* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  margin: auto;
  padding: 0;
  width: 390px;
  background: #454c59;
  overflow: hidden;
  /* border: 2px solid red; */
}

.cards {
  padding-top: 0px;
  text-align: center;
  display: flex;

  z-index: 1;
  user-select: none;
}

/* #card-stack {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  justify-content: center;
} */

.card {
  display: grid;
  position: relative;
  max-width: 390px;
  height: 100vh;
  background: #ffffff;
  border: 3px solid #272a33;

  /* border-radius: 15px; */
  overflow: hidden;
  /* margin-top: 1vh; */
  will-change: transform;
  transition: all 0.3s ease-in-out;
  cursor: -webkit-grab;
  cursor: grabbing;
}

.card img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  pointer-events: none;
}

.menu {
  display: grid;
  margin-left: 310px;
  margin-top: 20px;
  position: absolute;
  z-index: 5;
}
